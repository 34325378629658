import React, { Component, Suspense } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import './scss/style.scss';
import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import { setCurrentUser, logoutUser } from './actions/authActions';
import store from './store';
import PrivateRoute from './common/PrivateRoute';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));
const Login = React.lazy(() => import('./views/login/Login'));
const TwoFA = React.lazy(() => import('./views/2FA/TwoFA'));
const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'));

// const Register = React.lazy(() => import('./views/register/Register'));
// const Failure = React.lazy(() => import('./views/PlatformAuth/Failure'));
// const Success = React.lazy(() => import('./views/PlatformAuth/Success'));
// const Page404 = React.lazy(() => import('./components/errorComponents/Page404'));
// const Register404 = React.lazy(() => import('./components/errorComponents/Register404'));
// const Page500 = React.lazy(() => import('./components/errorComponents/Page500'));
// const Forgetpassword = React.lazy(() => import('./views/login/ForgetPassword'));
// const ForgetpasswordCompany = React.lazy(() => import('./views/login/ForgetPasswordCompany'));
// const ForgetpasswordCompanyLink = React.lazy(() => import('./views/login/ForgetPassLinkCompany'));

if (localStorage.access && localStorage.access != 'undefined') {
  setAuthToken(localStorage.access);
  const decoded = jwt_decode(localStorage.access);
  store.dispatch(setCurrentUser(decoded));
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());
    window.location.href = '/';
  }
}

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            {/* <Route path="/forgetpassword/:token" name="Forget Page" element={<Forgetpassword />} />
            <Route
              path="/companyforgetpassword/:token"
              name="Forget Page"
              element={<ForgetpasswordCompany />}
            />
            <Route
              path="/companyforgetpasswordlink"
              name="Forget Page"
              element={<ForgetpasswordCompanyLink />}
            /> */}
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/two-factor-auth" name="2FA" element={<TwoFA />} />
            {/* <Route exact path="/auth/signup-failed" name="Web Auth" element={<Failure />} />
            <Route exact path="/auth/signup-success" name="Web Auth" element={<Success />} />
            <Route
              exact
              path="/register/:register_key"
              name="Register Page"
              element={<Register />}
            />
            <Route
              path="/register/:email/:role/:companyId"
              name="Register Page"
              element={<Register />}
            /> */}
            {/* <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/Register404" name="Page 404" element={<Register404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} /> */}
            <Route element={<PrivateRoute />}>
              <Route path="*" name="Home" element={<DefaultLayout />} />
            </Route>
          </Routes>
        </Suspense>
      </HashRouter>
    );
  }
}

export default App;
