import axios from 'axios';
import Swal from 'sweetalert2';
import setAuthToken from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';
import { SET_CURRENT_USER, SET_LOADING } from './types';

export const authenticate2FA = (userData, navigate) => async (dispatch) => {
  //API to send code and get Access token
  dispatch(setLoading(true));
  try {
    let res = await axios.post(`${process.env.REACT_APP_BASE_URL}/verify-otp/`, userData);
    console.log(res, '======response=======');
    const { access, refresh, role } = res.data;
    localStorage.setItem('access', access);
    localStorage.setItem('role', role);
    localStorage.setItem('refresh', refresh);
    setAuthToken(access);
    const decoded = jwt_decode(access);
    console.log(decoded, '==========');
    dispatch(setCurrentUser(decoded));
    dispatch(setLoading(false));
    navigate('/dashboard');
  } catch (err) {
    dispatch(setLoading(false));
    console.log(err.response, '---err');
    return Swal.fire({
      title: '<span style="color: #c77171;">Login Failed!</span>',
      color: 'white',
      text: err?.response?.data?.error,
      background: '#3d3e47',
      confirmButtonColor: '#5299e0a6',
    });
  }
};
export const loginUser = (userData, navigate) => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    let res = await axios.post(`${process.env.REACT_APP_BASE_URL}/login/`, userData);
    // modification case:get success case from res to navigate 2FA page
    console.log(res, '======response=======');
    const obj = { userId: res.data.userId, message: res.data.message };
    dispatch(setLoading(false));
    navigate('/two-factor-auth', { state: obj });
  } catch (err) {
    dispatch(setLoading(false));
    console.log(err.response, '---err');
    return Swal.fire({
      title: '<span style="color: #c77171;">Login Failed!</span>',
      color: 'white',
      text: err?.response?.data?.error,
      background: '#3d3e47',
      confirmButtonColor: '#5299e0a6',
    });
  }
};

export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const setLoading = (loading) => {
  return {
    type: SET_LOADING,
    payload: loading,
  };
};

export const logoutUser = () => (dispatch) => {
  localStorage.clear();
  setAuthToken(false);
  dispatch(setCurrentUser({}));
};
