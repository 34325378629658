import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import themeReducer from './reducers/theme';
import authReducer from './reducers/authReducer';
import thunk from 'redux-thunk';
const middleware = [thunk];

const combinedReducers = combineReducers({
  theme: themeReducer,
  auth: authReducer,
});

const store = createStore(combinedReducers, compose(applyMiddleware(...middleware)));
export default store;
