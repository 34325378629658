import isEmpty from '../validation/is-empty';
import { SET_CURRENT_USER, SET_LOADING, SET_ROI, SET_ROI_LOADING } from '../actions/types';

const initialState = {
  isAuthenticated: false,
  user: {},
  loading: false,
  roi: {},
  roiLoading: false,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_ROI:
      return {
        ...state,
        roi: action.payload,
      };
    case SET_ROI_LOADING:
      return {
        ...state,
        roiLoading: action.payload,
      };
    default:
      return state;
  }
}
